import styled from 'styled-components';
import { bigPurple, greenColor, purpleColor } from '../../utils/colors';

const RootContainer = styled.div`
    display: flex;
    background-color: ${greenColor};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .content-div {
        display: flex;
        width: 100%;
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
`;

interface ContainerProps {
    color: string;
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: ${(props: ContainerProps) => props.color};

    .oneSideDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .margin-class {
        margin-bottom: 80px;
    }
`;

interface TitleSpanProps {
    titleColor: string;
}

const TitlesSpan = styled.span`
    color: ${(props: TitleSpanProps) => props.titleColor};
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
    width: 80%;
    padding: 30px 0;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

const ExplanPara = styled.p`
    margin: 15px 0 0 0;
    width: 80%;
    line-height: 30px;
    color: #fff;
`;

const SideImage = styled.img`
    width: 100%;
    height: auto;
    background-color: ${greenColor};
`;

const FillFormButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: ${bigPurple};
    color: #fff;
    padding: 10px 35px;
    margin-top: 2%;
    cursor: pointer;
    font-size: 18px;
    border-color: transparent;
    transition: 0.2s ease-in-out;
    margin-bottom: 30px;
    text-decoration: none;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }

    @media screen and (max-width: 480px) {
        font-size: 14px;
    }

    &:hover {
        background-color: ${purpleColor};
    }
`;

export { Container, TitlesSpan, RootContainer, ExplanPara, SideImage, FillFormButton };
