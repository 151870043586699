import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { purpleColor } from '../../utils/colors';

interface SidebarContainerProps {
    isOpen: boolean;
}

const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: #0d0d0d;
    display: grid;
    align-items: center;
    transition: 0.3s ease-in-out;
    opacity: ${(props: SidebarContainerProps) => (props.isOpen ? '100%' : '0')};
    right: ${(props: SidebarContainerProps) => (props.isOpen ? '0' : '-100%')};
`;

const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;

    svg path {
        fill: white;
    }
`;

const SidebarWrapper = styled.div`
    color: #fff;
`;

const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`;

const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: ${purpleColor};
        transition: 0.2 ease-in-out;
    }
`;

export { SidebarContainer, Icon, SidebarWrapper, SidebarLink, SidebarMenu };
