import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    TitlesSpan,
    RootContainer,
    ExplanPara,
    SideImage,
    FillFormButton,
} from './styles';
import { neutro } from '../../utils/colors';
import ImageMan from '../../assets/images/young-man-phone.png';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FgtsForm from '../FgtsForm';
import { scroller } from 'react-scroll';

const useStyles = makeStyles(() => ({
    popoverRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

interface InformationPageProps {
    openedForm: boolean;
}

const InformationPage = ({ openedForm }: InformationPageProps): JSX.Element => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    let formBtn: any;
    const navigate = useNavigate();

    const handleClose = () => {
        setAnchorEl(null);
        navigate('/');
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (openedForm) {
            formBtn.click();
            scroller.scrollTo('fgts-page', {
                duration: 500,
                delay: 400,
                smooth: true,
                spy: true,
                offset: -130,
            });
        }
    });

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <RootContainer id="fgts-page">
            <div className="content-div">
                <Container color={neutro}>
                    <SideImage src={ImageMan} />
                </Container>
                <Container color={neutro}>
                    <div className="oneSideDiv">
                        <TitlesSpan titleColor="#fff">
                            Como funciona o saque-aniversário FGTS?
                        </TitlesSpan>
                        <ExplanPara>
                            O crédito saque-aniversário consiste na antecipação da retirada do saldo
                            da conta do FGTS, ao qual o trabalhador tem possibilidade de efetuar o
                            seu saque anualmente, através do saque-aniversário no mês do seu
                            aniversário. Lembrando que isso só é possível se o trabalhador que optar
                            por esse saque possuir saldo disponível em sua(s) conta(s) vinculada(s)
                            do FGTS, sendo essa(s) ativa(s) ou inativa(s).
                        </ExplanPara>
                        <ExplanPara className="margin-class">
                            Essa nova modalidade de crédito possui taxa de juros atrativa e permite
                            a utilização desses recursos como garantia na contratação de empréstimo
                            no valor de até 05 (cinco) parcelas anuais do saque-aniversário.
                            Reforçando o que foi dito anteriormente, o pagamento é anual e mediante
                            o débito direto na(s) conta(s) vinculada(s) do FGTS.
                        </ExplanPara>
                        <FillFormButton ref={(btn) => (formBtn = btn)} onClick={handleClick}>
                            Contratar agora
                        </FillFormButton>
                        <Popover
                            id={id}
                            classes={{ root: classes.popoverRoot }}
                            anchorReference={'none'}
                            open={open}
                            onClose={handleClose}
                        >
                            <FgtsForm />
                        </Popover>
                    </div>
                </Container>
            </div>
        </RootContainer>
    );
};

export default InformationPage;
