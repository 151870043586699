import React, { useState } from 'react';
import { Container, TitlesSpan, RootContainer, SideImage, FillFormButton } from './styles';
import ImageMan from '../../assets/images/smilling-computer.jpg';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FgtsForm from '../FgtsForm';
import StepByStep from '../../components/StepByStep';

const useStyles = makeStyles(() => ({
    popoverRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const RequiresPage: React.FC = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <RootContainer id="fgts-page">
            <div className="content-div">
                <Container color="#fff">
                    <div className="oneSideDiv">
                        <TitlesSpan titleColor="#000">
                            Saiba quais são os pré-requisitos para o SAQUE-ANIVERSÁRIO
                        </TitlesSpan>
                        <StepByStep
                            steps={[
                                'Ser maior de 18 anos ou emancipado;',
                                "Estar com o CPF em situação regular na Receita Federal; <a target='_blank' rel='noopener noreferrer' href='https://servicos.receita.fazenda.gov.br/servicos/cpf/consultasituacao/consultapublica.asp' >Veja aqui</a>",
                                'Possuir conta corrente ou poupança;',
                                'Possuir saldo em sua conta FGTS;',
                                'Optar pelo saque-aniversário;',
                                'Autorizar o banco a ter acesso aos valores e dados do FGTS.',
                            ]}
                        />
                        <FillFormButton onClick={handleClick}>Contratar agora</FillFormButton>
                        <Popover
                            id={id}
                            classes={{ root: classes.popoverRoot }}
                            anchorReference={'none'}
                            open={open}
                            onClose={handleClose}
                        >
                            <FgtsForm />
                        </Popover>
                    </div>
                </Container>
                <Container color="#fff">
                    <SideImage src={ImageMan} />
                </Container>
            </div>
        </RootContainer>
    );
};

export default RequiresPage;
