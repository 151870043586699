import styled from 'styled-components';

const RootContainer = styled.div`
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content-div {
        display: flex;
        width: 100%;
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
    .center-popover {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    margin: 30px 0;
`;

interface ContainerProps {
    color: string;
}

const Container = styled.div`
    display: flex;
    padding: 30px 0 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: ${(props: ContainerProps) => props.color};

    .oneSideDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

interface TitleSpanProps {
    titleColor: string;
}

const TitlesSpan = styled.span`
    color: ${(props: TitleSpanProps) => props.titleColor};
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
    width: 80%;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

const Video = styled.iframe`
    width: 560px;
    height: 315px;
    margin: 30px;

    @media screen and (max-width: 830px) {
        width: 100%;
        height: 250px;
        margin: 30px 0;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        height: 250px;
        font-size: 18px;
    }
`;

export { Container, TitlesSpan, RootContainer, Video };
