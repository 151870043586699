import React, { useState } from 'react';
import { Popover } from '@material-ui/core';
import clsx from 'clsx';
import { Container, ButtonRootContainer, ButtonContent, ButtonText } from './styles';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

export interface FloatingButtonProps {
    label: string;
    children?: any;
}

function gtagReportConversion() {
    //@ts-ignore
    window.gtag('event', 'conversion', {
        send_to: 'AW-10906734319/NLLMCLDekd4DEO-d3tAo',
    });
    return false;
}

const FloatingButton = ({ label, children }: FloatingButtonProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        gtagReportConversion();
        if (anchorEl) handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Container>
            <ButtonRootContainer className={clsx({ 'popover-opened': open })} onClick={handleClick}>
                <ButtonContent>
                    {children && (
                        <Popover
                            id={id}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                        >
                            {children}
                        </Popover>
                    )}
                    <WhatsAppIcon fontSize="large" />
                    <ButtonText>{label?.toUpperCase()}</ButtonText>
                </ButtonContent>
            </ButtonRootContainer>
        </Container>
    );
};

export default FloatingButton;
