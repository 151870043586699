import styled from 'styled-components';

export const RootContainer = styled.div`
    .sticky-wrapper {
        position: relative;
        height: auto;
    }

    .sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
    }
`;
