import styled from 'styled-components';
import { neutro, purpleColor, bigPurple } from '../../utils/colors';

const DigitalContainer = styled.div`
    height: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${neutro};
    position: relative;
    padding-top: 30px;

    @media screen and (max-width: 900px) {
        height: 1200px;
    }

    @media screen and (max-width: 768px) {
        height: 1500px;
    }

    @media screen and (max-width: 500px) {
        height: 1700px;
    }

    @media screen and (max-width: 400px) {
        height: 1800px;
    }
`;

const DigitalH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;
    text-align: center;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

interface ImgBgProps {
    opc: number;
}

const ImgBg = styled.img`
    width: 661px;
    height: 430px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    opacity: ${(props: ImgBgProps) => props.opc};
`;

const ContainerWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 20px auto;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const InsideContainer = styled.div`
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 100%;

    @media screen and (max-width: 768px) {
        width: 90%;
    }
`;

const BanksContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
`;

const BanksImg = styled.img`
    margin: 30px;

    @media screen and (max-width: 820px) {
        margin: 0;
    }
`;

const OpenAccountButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: ${bigPurple};
    color: #fff;
    padding: 10px 35px;
    margin-top: 2%;
    cursor: pointer;
    font-size: 18px;
    border-color: transparent;
    transition: 0.2s ease-in-out;
    margin-bottom: 30px;
    text-decoration: none;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }

    @media screen and (max-width: 480px) {
        font-size: 14px;
    }

    &:hover {
        background-color: ${purpleColor};
    }
`;

const ExplanPara = styled.p`
    margin: 15px 0 0 0;
    width: 80%;
    line-height: 30px;
    color: #fff;

    @media screen and (max-width: 800px) {
        width: 90%;
    }
`;

export {
    DigitalContainer,
    DigitalH1,
    ImgBg,
    ContainerWrapper,
    InsideContainer,
    BanksContainer,
    BanksImg,
    OpenAccountButton,
    ExplanPara,
};
