import styled from 'styled-components';

interface ImageContainerProps {
    image: string;
}

const ImageContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 331px;
    height: 185px;
    position: relative;
    margin: 15px;
    flex-wrap: nowrap;
    flex-shrink: 0;

    .bg {
        position: absolute;
        z-index: 1;
        background-image: url(${(props: ImageContainerProps) => props.image});
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(50%);
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }
`;

const LabelSpan = styled.span`
    margin: 15px;
    color: #fff;
    font-weight: bold;
    opacity: 1;
    z-index: 2;
`;

export { ImageContainer, LabelSpan };
