import React from 'react';
import {
    RootContainer,
    Container,
    MenuItemsContainer,
    ImageContainer,
    MenuItems,
    MobileIcon,
} from './styles';
import whiteLogo from '../../assets/images/Logotipo-branco.png';
import purpleLogo from '../../assets/images/Logotipo-purple-white.png';
import DehazeIcon from '@material-ui/icons/Dehaze';

interface HeaderProps {
    isSticky: boolean;
    toggle: () => void;
}

export const menuItems = [
    { label: 'Início', link: 'home' },
    { label: 'Serviços', link: 'produtos' },
    { label: 'FGTS', link: 'fgts-page' },
    { label: 'INSS', link: 'constructing' },
    { label: 'Contas Digitais', link: 'digital-accounts' },
];

const Header = ({ isSticky, toggle }: HeaderProps): JSX.Element => {
    return (
        <RootContainer isSticky={isSticky}>
            <Container>
                <ImageContainer
                    isSticky={isSticky}
                    to="home"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-130}
                >
                    <img
                        src={isSticky ? whiteLogo : purpleLogo}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </ImageContainer>
                <MobileIcon onClick={toggle}>
                    <DehazeIcon fontSize="large" />
                </MobileIcon>
                <MenuItemsContainer>
                    {menuItems.map((menu: any) => (
                        <MenuItems
                            isSticky={isSticky}
                            key={menu.label}
                            to={menu.link}
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-130}
                        >
                            {menu.label.toUpperCase()}
                        </MenuItems>
                    ))}
                </MenuItemsContainer>
            </Container>
        </RootContainer>
    );
};

export default Header;
