import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/mainPage';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage openedForm={false} />} />
                <Route path="/fgts" element={<MainPage openedForm={true} />} />
            </Routes>
        </Router>
    );
};

export default App;
