import React from 'react';
import { Container, TitlesSpan, ImageCardsContainer } from './styles';
import InformationCard from '../../components/InformationCard';
import Image1 from '../../assets/images/information-card-mock.png';
import Image2 from '../../assets/images/empreendedor.png';
import Image3 from '../../assets/images/reform.jpg';
import { neutro } from '../../utils/colors';

const DreamsPage: React.FC = () => {
    return (
        <Container color={neutro}>
            <TitlesSpan titleColor="#fff">
                A midas pode te ajudar a realizar os seus sonhos!
            </TitlesSpan>
            <ImageCardsContainer>
                <InformationCard image={Image2} label="Inicie o seu tão sonhado empreendimento" />
                <InformationCard image={Image3} label="Tire sua reforma do papel" />
                <InformationCard image={Image1} label="Ajude seu filho(a) a conquistar o diploma" />
            </ImageCardsContainer>
        </Container>
    );
};

export default DreamsPage;
