import React, { useState } from 'react';
import { Container } from './styles';
import {
    Typography,
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Autocomplete,
} from '@material-ui/core';
import emailjs from 'emailjs-com';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { USER_ID, TEMPLATE_ID, SERVICE_ID } from '../../utils/emailkey';

function gtagReportConversion() {
    //@ts-ignore
    window.gtag('event', 'conversion', {
        send_to: 'AW-10906734319/57AgCK3ekd4DEO-d3tAo',
    });
    return false;
}

const states = [
    { label: 'Acre - AC' },
    { label: 'Alagoas - AL' },
    { label: 'Amapá - AP' },
    { label: 'Amazonas - AM' },
    { label: 'Bahia - BA' },
    { label: 'Ceará - CE' },
    { label: 'Espírito Santo - ES' },
    { label: 'Goiás - GO' },
    { label: 'Maranhão - MA' },
    { label: 'Mato Grosso - MT' },
    { label: 'Mato Grosso do Sul - MS' },
    { label: 'Minas Gerais - MG' },
    { label: 'Pará - PA' },
    { label: 'Paraíba - PB' },
    { label: 'Paraná - PR' },
    { label: 'Pernambuco - PE' },
    { label: 'Piauí - PI' },
    { label: 'Rio de Janeiro - RJ' },
    { label: 'Rio Grande do Norte - RN' },
    { label: 'Rio Grande do Sul - RS' },
    { label: 'Rondônia - RO' },
    { label: 'Roraima - RR' },
    { label: 'Santa Catarina - SC' },
    { label: 'São Paulo - SP' },
    { label: 'Sergipe - SE' },
    { label: 'Tocantins - TO' },
    { label: 'Distrito Federal - DF' },
];

const useStyles = makeStyles(() => ({
    popoverRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const FgtsForm: React.FC = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        gtagReportConversion();
        emailjs
            .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
            .then(() => {
                e.target.reset();
            })
            .catch((error) => console.log(error));
    };

    return (
        <Container>
            <Typography gutterBottom variant="h4" style={{ margin: '15px' }}>
                Saque-Aniversário FGTS
            </Typography>
            <Card style={{ maxWidth: '700px', margin: '0 auto', padding: '20px 5px' }}>
                <form onSubmit={handleSubmit}>
                    <CardContent>
                        <Typography gutterBottom variant="h5">
                            Dados pessoais
                        </Typography>
                        <Typography
                            gutterBottom
                            variant="body2"
                            component="p"
                            color="textSecondary"
                            style={{ marginBottom: '15px' }}
                        >
                            Qualquer duvida contate nosso time pelo WhatsApp
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid xs={12} item>
                                <TextField
                                    name="full_name"
                                    label="Nome Completo"
                                    placeholder="Informe seu nome completo"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    name="phone"
                                    type="tel"
                                    label="Telefone"
                                    placeholder="(99) 9999-9999"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    name="birthday"
                                    label="Data de nascimento"
                                    placeholder="dd/mm/aaaa"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    name="mom_name"
                                    label="Nome da mãe"
                                    placeholder="Informe o nome completo da sua mãe"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    name="rg"
                                    label="RG"
                                    placeholder="9999999"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    name="cpf"
                                    label="CPF"
                                    placeholder="999.999.999-99"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                        <Typography gutterBottom variant="h5">
                            Endereço
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid xs={12} item>
                                <TextField
                                    name="address"
                                    label="Endereço"
                                    placeholder="Informe o seu endereço"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    name="number"
                                    label="Número"
                                    placeholder="Informe o número da sua residência"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    name="district"
                                    label="Bairro"
                                    placeholder="Informe o seu bairro"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    name="city"
                                    label="Cidade"
                                    placeholder="Informe a sua cidade"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <Autocomplete
                                    disablePortal
                                    options={states}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="state"
                                            label="Estado - UF"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    name="cep"
                                    label="CEP"
                                    placeholder="99.999-999"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                        <Typography gutterBottom variant="h5">
                            Dados Bancários
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid xs={12} item>
                                <TextField
                                    name="bank"
                                    label="Banco"
                                    placeholder="Informe o código e/ou nome do seu banco"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    name="agency"
                                    label="Agência"
                                    placeholder="Informe a sua agência"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    name="account"
                                    label="Conta"
                                    placeholder="Informe o número da sua conta"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <Autocomplete
                                    disablePortal
                                    options={[
                                        { label: 'Conta Corrente' },
                                        { label: 'Conta Poupança' },
                                    ]}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="account_type"
                                            label="Tipo da conta"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClick}
                                >
                                    Enviar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </form>
            </Card>
            <Popover
                id={id}
                classes={{ root: classes.popoverRoot }}
                anchorReference={'none'}
                open={open}
                onClose={handleClose}
            >
                <div
                    style={{
                        margin: '15px',
                        maxWidth: '300px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <h1>Sucesso!</h1>
                    <h5 style={{ textAlign: 'center', margin: '20px 0' }}>
                        Para confirmar o entendimento sobre o saque-aniversário, clique neste botão
                        para falar com um atendente
                    </h5>
                    <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://wa.me/558140404272"
                    >
                        Fale Conosco
                    </Button>
                </div>
            </Popover>
        </Container>
    );
};

export default FgtsForm;
