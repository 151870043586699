import styled from 'styled-components';
import { neutro } from '../../utils/colors';

const ServicesContainer = styled.div`
    height: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${neutro};
    position: relative;

    @media screen and (max-width: 830px) {
        height: 1500px;
    }

    @media screen and (max-width: 768px) {
        height: 2400px;
    }

    @media screen and (max-width: 480px) {
        height: 2700px;
    }
`;

const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

interface ImgBgProps {
    opc: number;
}

const ImgBg = styled.img`
    width: 661px;
    height: 430px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    opacity: ${(props: ImgBgProps) => props.opc};
`;

export { ServicesContainer, ServicesH1, ImgBg };
