import styled from 'styled-components';

interface ContainerProps {
    color: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${(props: ContainerProps) => props.color};
    padding: 30px 0;
`;

interface TitleSpanProps {
    titleColor: string;
}

const TitlesSpan = styled.span`
    margin-bottom: 15px;
    color: ${(props: TitleSpanProps) => props.titleColor};
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    width: 50%;

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

const ImageCardsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 768px) {
        overflow-x: scroll;
        justify-content: flex-start;
    }
`;

export { Container, TitlesSpan, ImageCardsContainer };
