import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 600px;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

export const ImagesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
`;
