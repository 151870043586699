import React from 'react';
import { RootContainer, Stepper, StepDiv, LabelSpan } from './styles';
import DOMPurify from 'dompurify';

interface StepByStepProps {
    steps: string[];
}

const StepByStep = ({ steps }: StepByStepProps): JSX.Element => {
    let stepCounter = 0;
    return (
        <RootContainer>
            {steps.map((step) => (
                <StepDiv key={stepCounter}>
                    <Stepper>{++stepCounter}°</Stepper>
                    <LabelSpan
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(step) }}
                    ></LabelSpan>
                </StepDiv>
            ))}
        </RootContainer>
    );
};

export default StepByStep;
