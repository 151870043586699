import React from 'react';
import { Container, ImagesContainer } from './styles';

interface BanksPopProps {
    image: string;
    width: string;
    height: string;
    qrcodeImg: string;
    linkApple: string;
    linkGoogle: string;
    working: boolean;
}

const BanksPop = ({
    image,
    width,
    height,
    qrcodeImg,
    working,
    linkApple,
    linkGoogle,
}: BanksPopProps): JSX.Element => {
    return (
        <Container>
            {working ? (
                <>
                    <img width={width} height={height} src={image} style={{ margin: '20px' }} />
                    <h3>Escaneie o QR Code com seu celular:</h3>
                    <img width="214px" height="214px" src={qrcodeImg} style={{ margin: '20px' }} />
                    <h3>Ou escolha sua plataforma:</h3>
                    <ImagesContainer>
                        <a href={linkApple} target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://github.com/AGX-Software/assets/blob/master/apple-store.png?raw=true"
                                width="200px"
                                height="77px"
                            />
                        </a>
                        <a href={linkGoogle} target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://github.com/AGX-Software/assets/blob/master/google-play.png?raw=true"
                                width="200px"
                                height="77px"
                            />
                        </a>
                    </ImagesContainer>
                </>
            ) : (
                <div style={{ margin: '200px' }}>Disponivel em breve</div>
            )}
        </Container>
    );
};

export default BanksPop;
