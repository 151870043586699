import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { purpleColor } from '../../utils/colors';
import { Link as LinkR } from 'react-router-dom';

interface RootContainerProps {
    isSticky: boolean;
}

const RootContainer = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;
    background-color: ${(props: RootContainerProps) => props.isSticky && 'rgba(38, 38, 38, 0.5)'};
    color: ${(props: RootContainerProps) => props.isSticky && '#FFF'};
`;
//rgba(38, 38, 38, 0.5)
//rgba(106, 102, 242, 0.5)
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 60%;
    flex-direction: row;
    font-size: small;
    padding: 10px 24px 0 24px;

    @media screen and (max-width: 1400px) {
        transition: 0.8s all ease;
        width: 70%;
    }

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        width: 100%;
    }
`;

const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: flex;
        cursor: pointer;
        align-items: center;
    }
`;

const MenuItemsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const MenuItems = styled(LinkS)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    height: 100%;
    cursor: pointer;
    font-weight: 600;

    &.active {
        border-bottom: 3px solid;
        border-bottom-color: ${(props: RootContainerProps) =>
            props.isSticky ? purpleColor : 'transparent'};
    }
`;

const ImageContainer = styled(LinkS)`
    width: ${(props: RootContainerProps) => (props.isSticky ? '216px' : '270px')};
    height: ${(props: RootContainerProps) => (props.isSticky ? '92px' : '115px')};
    transition: 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-size: large;
    font-weight: bold;

    @media screen and (max-width: 768px) {
        height: 77px;
        width: 180px;
    }
`;

export { RootContainer, Container, MenuItemsContainer, ImageContainer, MenuItems, MobileIcon };
