import React from 'react';
import { ImageContainer, LabelSpan } from './styles';

interface InformationCardProps {
    image: string;
    label: string;
}

const InformationCard = ({ image, label }: InformationCardProps): JSX.Element => {
    return (
        <ImageContainer image={image}>
            <div className="bg" />
            <LabelSpan>{label}</LabelSpan>
        </ImageContainer>
    );
};

export default InformationCard;
