import styled from 'styled-components';
import { bigPurple, purpleColor } from '../../utils/colors';
import { Link as LinkS } from 'react-scroll';

const RootContainer = styled.div`
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .caroussel {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .react-multi-carousel-dot-list {
        margin-bottom: 40px;
        button {
            width: 20px;
            height: 20px;
            border-color: ${bigPurple};
        }
    }
    .react-multi-carousel-dot--active {
        button {
            background-color: ${bigPurple};
        }
    }
`;

const Slide = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
`;

const BannerH1 = styled.h1`
    color: #fff;
    text-align: center;
    font-size: 48px;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

const BannerP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

const ButtomBottom = styled(LinkS)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: ${bigPurple};
    color: #fff;
    padding: 10px 35px;
    margin-top: 2%;
    cursor: pointer;
    font-size: 18px;
    border-color: transparent;
    transition: 0.2s ease-in-out;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }

    @media screen and (max-width: 480px) {
        font-size: 14px;
    }

    &:hover {
        background-color: ${purpleColor};
    }
`;

export { RootContainer, TextDiv, ButtomBottom, Slide, BannerH1, BannerP };
