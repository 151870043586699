import React from 'react';
import { ServicesWrapper, ServicesH2, ServicesP, ServicesCard, ServicesIcon } from './styles';

interface ServicesProps {
    icon: any;
    title: string;
    description: string;
    component: string;
}

interface ServicesCardsProps {
    servicesList: ServicesProps[];
}

const ServicesCards = ({ servicesList }: ServicesCardsProps): JSX.Element => {
    return (
        <ServicesWrapper>
            {servicesList.map((service) => (
                <ServicesCard
                    key={service.title}
                    to={service.component}
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-130}
                >
                    <ServicesIcon src={service.icon} />
                    <ServicesH2>{service.title}</ServicesH2>
                    <ServicesP>{service.description}</ServicesP>
                </ServicesCard>
            ))}
        </ServicesWrapper>
    );
};

export default ServicesCards;
