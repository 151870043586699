import React from 'react';

import {
    Container,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    FooterText,
} from './styles';

const Footer: React.FC = () => {
    return (
        <Container>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Midas soluções digitais</FooterLinkTitle>
                            <FooterText>Rua Padre Euclides Jardim, 248, Afogados</FooterText>
                            <FooterText>Recife-PE, Brasil</FooterText>
                            <FooterText>CEP: 50.750-090</FooterText>
                            <FooterLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.google.com/maps/place/R.+Padre+Eucl%C3%ADdes+Jardim,+248+-+Afogados,+Recife+-+PE,+50750-090/@-8.073378,-34.9075978,17z/data=!3m1!4b1!4m5!3m4!1s0x7ab1f2ab702a095:0xf463a6edf946dd01!8m2!3d-8.073378!4d-34.9054091"
                            >
                                Ver no mapa
                            </FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Contato</FooterLinkTitle>
                            <FooterLink target="_blank" href="mailto:midassolucoesdig@gmail.com">
                                midassolucoesdig@gmail.com
                            </FooterLink>
                            <FooterLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://wa.me/558140404272"
                            >
                                +55 81 4040-4272
                            </FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
            </FooterWrap>
        </Container>
    );
};

export default Footer;
