import React from 'react';
import { SidebarContainer, Icon, SidebarWrapper, SidebarLink, SidebarMenu } from './styles';
import CloseIcon from '@material-ui/icons/Close';

import { menuItems } from '../header';

interface SideBarProps {
    isOpen: boolean;
    toggle: () => void;
}

const SideBar = ({ isOpen, toggle }: SideBarProps): JSX.Element => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon fontSize="large" />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {menuItems.map((item) => (
                        <SidebarLink
                            key={item.label}
                            onClick={toggle}
                            to={item.link}
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-80}
                        >
                            {item.label}
                        </SidebarLink>
                    ))}
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default SideBar;
