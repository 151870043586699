import styled from 'styled-components';
import { purpleColor } from '../../utils/colors';

const RootContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 30px;
    z-index: 3;
`;

const StepDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Stepper = styled.span`
    font-size: 24px;
    color: ${purpleColor};
    font-weight: bold;

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
`;

const LabelSpan = styled.span`
    font-size: 18px;
    width: 90%;
    margin: 20px 15px;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }

    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
`;

export { RootContainer, StepDiv, Stepper, LabelSpan };
