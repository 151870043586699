import styled from 'styled-components';

const Container = styled.div`
    span {
        font-family: 'DMSans-Regular';
    }

    .popover-opened {
        max-width: 1000px;
    }
`;

const ButtonRootContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-shrink: 0;
    white-space: nowrap;
    align-items: center;
    width: max-content;
    max-width: 78px;
    height: 78px;
    border-radius: 50px;
    background: #26d367;
    transition: 1s;
    cursor: pointer;

    &:hover {
        max-width: 1000px;
    }
`;

const ButtonContent = styled.div`
    display: flex;
    justify-content: center;
    vertical-align: middle;
    padding: 0 22px;

    svg path {
        fill: #fff;
    }
`;

const ButtonText = styled.span`
    display: flex;
    align-items: center;
    width: max-content;
    padding-left: 24px;
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
`;

export { Container, ButtonRootContainer, ButtonContent, ButtonText };
