import React from 'react';
import Banner from '../../components/banner';
import { RootBackground, RootContainer, VideoBg } from './styles';
import Video from '../../assets/videos/bgVideo.mp4';

const HomePage: React.FC = () => {
    return (
        <RootContainer id="home">
            <RootBackground>
                <VideoBg autoPlay loop muted>
                    <source src={Video} type="video/mp4" />
                </VideoBg>
            </RootBackground>
            <Banner />
        </RootContainer>
    );
};

export default HomePage;
