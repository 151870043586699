import React, { useState } from 'react';
import {
    DigitalContainer,
    DigitalH1,
    ImgBg,
    InsideContainer,
    ContainerWrapper,
    BanksContainer,
    BanksImg,
    OpenAccountButton,
    ExplanPara,
} from './styles';
import logoPan from '../../assets/images/bancopan.png';
import logoBmg from '../../assets/images/bancobmg.png';
import Logo from '../../assets/images/M-Midas-purple.png';
import BanksPop from '../BanksPop';
import { makeStyles } from '@material-ui/styles';
import { Popover } from '@material-ui/core';
import qrCodePan from '../../assets/images/qrcodepan.png';

const useStyles = makeStyles(() => ({
    popoverRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const DigitalAccounts: React.FC = () => {
    const classes = useStyles();
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);

    const handleClose = () => {
        setAnchorEl1(null);
        setAnchorEl2(null);
    };

    const handleClick1 = (event: any) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClick2 = (event: any) => {
        setAnchorEl2(event.currentTarget);
    };

    const openPop1 = Boolean(anchorEl1);
    const openPop2 = Boolean(anchorEl2);

    return (
        <DigitalContainer id="digital-accounts">
            <DigitalH1>Abra sua conta digital</DigitalH1>
            <ContainerWrapper>
                <InsideContainer>
                    <h2>Bancos Parceiros</h2>
                    <BanksContainer>
                        <BanksImg width="410px" height="178px" src={logoPan} />
                        <OpenAccountButton onClick={handleClick1}>
                            Abra sua conta no banco Pan
                        </OpenAccountButton>
                        <Popover
                            classes={{ root: classes.popoverRoot }}
                            anchorReference={'none'}
                            open={openPop1}
                            onClose={handleClose}
                        >
                            <BanksPop
                                qrcodeImg={qrCodePan}
                                width="317px"
                                height="180px"
                                image={logoPan}
                                linkApple="https://app.appsflyer.com/id1410400504?pid=agx_int&af_siteid=12&af_ad_id=615479ee9927030004d0d3f3&clickid=6197f868ee1fa900043df276&af_sub_siteid=6197f868ee1fa900043df276"
                                linkGoogle="https://app.appsflyer.com/br.com.bancopan.cartoes?pid=agx_int&af_siteid=12&af_ad_id=615479ee9927030004d0d3f3&clickid=6197f868ee1fa900043df276&af_sub_siteid=6197f868ee1fa900043df276"
                                working
                            />
                        </Popover>
                    </BanksContainer>
                    <BanksContainer>
                        <BanksImg width="317px" height="180px" src={logoBmg} />
                        <OpenAccountButton onClick={handleClick2}>
                            Abra sua conta no banco BMG
                        </OpenAccountButton>
                        <Popover
                            classes={{ root: classes.popoverRoot }}
                            anchorReference={'none'}
                            open={openPop2}
                            onClose={handleClose}
                        >
                            <BanksPop
                                qrcodeImg={qrCodePan}
                                width="363px"
                                height="205px"
                                image={logoBmg}
                                working={false}
                                linkApple="null"
                                linkGoogle="null"
                            />
                        </Popover>
                    </BanksContainer>
                </InsideContainer>
                <InsideContainer>
                    <h2 style={{ textAlign: 'center' }}>Qual é a vantagem de uma conta digital?</h2>
                    <ExplanPara>
                        Uma das vantagens é o não pagamento das taxas mensais. E também que qualquer
                        pessoa pode abrir uma conta pelo próprio aplicativo. Quem já está bem
                        ambientado com o mundo digital não terá qualquer problema em gerenciar sua
                        conta sem depender de uma pessoa do banco.
                    </ExplanPara>
                    <ExplanPara>
                        A conta digital também oferece a possibilidade de guardar dinheiro e pagar
                        contas de um jeito simples e fácil. Além disso, dá mais autonomia na
                        resolução de problemas e interação com serviços oferecidos;
                    </ExplanPara>
                    <ExplanPara>
                        No momento em que o cliente estabelece um relacionamento com a instituição,
                        ela vai usar a tecnologia para oferecer produtos e serviços que se adequem a
                        necessidade da pessoa;
                    </ExplanPara>
                    <ExplanPara>
                        Grande parte das fintechs que estão oferecendo contas digitais estão
                        trabalhando conceitos e conteúdos complementares de educação financeira. E,
                        desta forma, estão criando uma nova geração de brasileiros que irão
                        valorizar mais o dinheiro.
                    </ExplanPara>
                </InsideContainer>
            </ContainerWrapper>
            <ImgBg src={Logo} opc={0.15} />
        </DigitalContainer>
    );
};

export default DigitalAccounts;
