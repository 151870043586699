import React from 'react';
import { Container, TitlesSpan, RootContainer, Video } from './styles';
import StepByStep from '../../components/StepByStep';
import InformationPage from '../../pages/informationPage';
import DreamsPage from '../dreamsPage';
import RequiresPage from '../requiresPage';

interface ProdFgtsProps {
    openedForm: boolean;
}

const ProdFgts = ({ openedForm }: ProdFgtsProps): JSX.Element => {
    return (
        <div id="fgts-page">
            <RootContainer>
                <div className="content-div">
                    <Container color="#fff">
                        <TitlesSpan titleColor="#000">
                            Saiba como autorizar os bancos a consultarem o seu FGTS
                        </TitlesSpan>
                        <Video src="https://www.youtube.com/embed/ZF_FHKw37Nc" />
                    </Container>
                    <Container color="#fff">
                        <div className="oneSideDiv">
                            <TitlesSpan titleColor="#000">
                                Quer antecipar seu FGTS sem sair de casa? Veja como é rápido e
                                fácil:
                            </TitlesSpan>
                            <StepByStep
                                steps={[
                                    'Preencha o formulário abaixo com seus dados.',
                                    'Abra o aplicativo da caixa e selecione a opção de saque-aniversário',
                                    'Autorize os bancos a consultarem seu FGTS',
                                    'Você receberá um link de formalização para assinar seu contrato digitalmente (SMS).',
                                    'Assine o contrato no conforto de sua casa.',
                                    'Pronto! Agora só aproveitar o valor depositado em sua conta.',
                                    'Se precisar, fale com um de nossos consultores',
                                ]}
                            />
                        </div>
                    </Container>
                </div>
            </RootContainer>
            <DreamsPage />
            <InformationPage openedForm={openedForm} />
            <RequiresPage />
        </div>
    );
};

export default ProdFgts;
