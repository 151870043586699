import React from 'react';
import { RootContainer, TextDiv, ButtomBottom, Slide, BannerH1, BannerP } from './styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const Banner: React.FC = () => {
    return (
        <RootContainer>
            <Carousel
                responsive={responsive}
                className="caroussel"
                showDots={true}
                autoPlay={true}
                infinite={true}
                autoPlaySpeed={5000}
                arrows={false}
            >
                <Slide>
                    <TextDiv>
                        <BannerH1>Empréstimo saque-aniversário FGTS</BannerH1>
                        <BannerP>
                            Antecipe o seu saque-aniversário sem sair de casa com garantia FGTS!
                        </BannerP>
                        <ButtomBottom
                            to="fgts-page"
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-80}
                        >
                            Saiba mais
                        </ButtomBottom>
                    </TextDiv>
                </Slide>
                <Slide>
                    <TextDiv>
                        <BannerH1>Abra a sua conta digital</BannerH1>
                        <BannerP>
                            Abra sua conta em um banco digital parceiro, e ganhe todas as vantagens
                            de ter uma conta digital!
                        </BannerP>
                        <ButtomBottom
                            to="fgts-page"
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-80}
                        >
                            Saiba mais
                        </ButtomBottom>
                    </TextDiv>
                </Slide>
                <Slide>
                    <TextDiv>
                        <BannerH1>Empréstimo Consignado</BannerH1>
                        <BannerP>
                            Empréstimo, portabilidade e refinanciamento para emprestimos
                            consignados!
                        </BannerP>
                        <ButtomBottom
                            to="fgts-page"
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-80}
                        >
                            Saiba mais
                        </ButtomBottom>
                    </TextDiv>
                </Slide>
            </Carousel>
        </RootContainer>
    );
};

export default Banner;
