import React, { useRef, useState, useEffect } from 'react';
import Header from '../../components/header';
import FloatingButton from '../../components/floatingButton';
import HomePage from '../../pages/homePage';
import ProdFgts from '../../pages/prodFgts';
import SideBar from '../../components/sideBar';
import ServicesPage from '../../pages/ServicesPage';
import { RootContainer } from './styles';
import Footer from '../../components/Footer';
import DigitalAccounts from '../DigitalAccounts';
import { useLocation } from 'react-router-dom';

interface MainPageProps {
    openedForm: boolean;
}

const MainPage = ({ openedForm }: MainPageProps): JSX.Element => {
    const [isSticky, setIsSticky] = useState(false);
    const [onTop, setOnTop] = useState(false);
    const ref = useRef<HTMLInputElement>(null);
    const location = useLocation();

    useEffect(() => {
        //@ts-ignore
        window.gtag('config', 'AW-10906734319', {
            page_title: location.pathname,
            page_path: location.pathname,
        });
    }, [location]);

    const handleScroll = () => {
        if (ref.current) {
            setIsSticky(ref.current.getBoundingClientRect().top <= 0);
        }

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height =
            document.documentElement.scrollHeight - document.documentElement.clientHeight;

        const scrolled = winScroll / height;
        if (scrolled > 0) {
            setOnTop(true);
        } else {
            setOnTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    const [isOpen, setIsOpen] = useState(false);

    const toggleSideMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <RootContainer>
            <div className={`sticky-wrapper ${isSticky && ' sticky'}`} ref={ref}>
                <SideBar isOpen={isOpen} toggle={toggleSideMenu} />
                <Header isSticky={onTop} toggle={toggleSideMenu} />
            </div>
            <a
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    position: 'fixed',
                    bottom: '80px',
                    right: '80px',
                    zIndex: 100,
                    textDecoration: 'none',
                }}
                href="https://wa.me/558140404272"
            >
                <FloatingButton label="Chamar no WhatsApp">
                    Clique em continuar para o chat
                </FloatingButton>
            </a>
            <HomePage />
            <ServicesPage />
            <ProdFgts openedForm={openedForm} />
            <DigitalAccounts />
            <Footer />
        </RootContainer>
    );
};

export default MainPage;
