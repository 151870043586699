import React from 'react';
import { ServicesContainer, ServicesH1, ImgBg } from './styles';
import Icon1 from '../../assets/icons/business-deal.svg';
import Icon2 from '../../assets/icons/graphic-up.svg';
import Icon3 from '../../assets/icons/retire.svg';
import Icon4 from '../../assets/icons/control-panel.svg';
import Icon5 from '../../assets/icons/man-with-phone.svg';
import Icon6 from '../../assets/icons/done-man.svg';
import Logo from '../../assets/images/M-Midas-purple.png';
import ServicesCards from '../../components/ServicesCards';

const servicesList = [
    {
        icon: Icon2,
        title: 'Emprestimo saque-aniversário FGTS',
        description: 'Antecipe o seu saque-aniversário sem sair de casa com garantia FGTS!',
        component: 'fgts-page',
    },
    {
        icon: Icon1,
        title: 'Conta no banco Pan',
        description:
            'Tenha uma Conta Digital PAN. Você não paga mensalidade e ainda tem crédito justo',
        component: 'digital-accounts',
    },
    {
        icon: Icon5,
        title: 'Conta no banco BMG',
        description:
            ' O Banco Bmg possui todos os serviços bancários que você precisa. Abra a sua conta agora!',
        component: 'digital-accounts',
    },
    {
        icon: Icon3,
        title: 'Emprestimo Consignado',
        description: 'Emprestimo Consignado para aposentados e pensionistas do INSS',
        component: 'constructing',
    },
    {
        icon: Icon4,
        title: 'Portabilidade de empréstimo consignado',
        description:
            'Peça a transferência da dívida para o outro banco e tenha condições diferenciadas para seu bolso!',
        component: 'constructing',
    },
    {
        icon: Icon6,
        title: 'Refinancie seu empréstimo consignado',
        description:
            'Precisa de uma folga no orçamento? Com o refinanciamento, você quita os empréstimos anteriores e tem acesso a mais crédito.',
        component: 'constructing',
    },
];

const ServicesPage: React.FC = () => {
    return (
        <ServicesContainer id="produtos">
            <ServicesH1>Nossos serviços</ServicesH1>
            <ServicesCards servicesList={servicesList} />
            <ImgBg src={Logo} opc={0.15} />
        </ServicesContainer>
    );
};

export default ServicesPage;
